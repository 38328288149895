import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import locoScroll from '../assets/js/lenisScroll'
import useTransitionAndScroll from '../composables/useTransitionAndScroll'
import emitter from '../scripts/emitter'
import { gsap } from 'gsap'

const { site } = window.__VUE_WORDPRESS__.state
const { callBack } = useTransitionAndScroll()

window.retryCounter = 0

const scrollToSection = (to, resolve) => {
  locoScroll.resize()
  if (to.hash) {
    const scrollToSection = () => {
      const toEl = document.querySelector(to.hash)
      if (toEl) {
        locoScroll.scrollTo(toEl, {
          duration: 0.3,
          onComplete: () => {
            callBack(resolve)
            window.retryCounter = 0
          },
        })
      } else {
        setTimeout(() => {
          if (window.retryCounter > 10) {
            console.error('Could not find the element to scroll to')
            console.error('defaulting to top of the page')
            callBack(resolve)
            window.retryCounter = 0
          } else {
            console.warn(
              'Retrying to find the element to scroll to, attempt: ',
              window.retryCounter
            )
            window.retryCounter++
            scrollToSection()
          }
        }, 100)
      }
    }
    scrollToSection()
  } else {
    locoScroll.scrollTo(to, {
      duration: 0.3,
      onComplete: () => {
        callBack(resolve)
      },
    })
  }
}

export const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      emitter.once('scrollPing', () => {
        if (to.hash) {
          scrollToSection(to, resolve)
        } else {
          if (savedPosition) {
            const scrollValue =
              savedPosition.top === 0 ? 'top' : savedPosition.top
            scrollToSection(scrollValue, resolve)
          } else {
            callBack(resolve)
          }
        }
      })
    })
  },
})

router.afterEach((to) => {
  // set page title on route change
  window.document.title =
    `${to.meta.title} | ${site.name}` || `${site.name} | ${site.description}`
})

router.beforeEach((to, from, next) => {
  // hide the logo
  gsap.set('#header-nav .branding #header-logos', {
    opacity: 0,
    duration: 1,
    clearProps: 'all',
    onComplete: () => {
      // set attibuttes on body
      const body = document.querySelector('body')
      const logoContainer = document.getElementById('header-logos')

      body.setAttribute('data-page-color', to.meta.header_color)

      // update logo

      if (logoContainer) {
        logoContainer.className = 'logo-container'
        if (to.meta.logo_color) {
          logoContainer.classList.add(to.meta.logo_color)
        }
      }

      const nav = document.getElementById('nav-container')

      // update navigation
      if (nav) {
        nav.className = 'nav-wrapper'
        if (to.meta.header_color) {
          nav.classList.add(to.meta.header_color)
        }
      }

      next()
    },
  })
  // // animate label
  // const label = document.querySelector('#header-label-container')
  // if (label) {
  //   gsap.to(label, {
  //     duration: 1,
  //     y: -150,
  //     ease: 'power4.in',
  //     onComplete: function () {
  //       next()
  //     },
  //   })
  // }
})
