<template>
  <transition>
    <footer
      v-if="acf_options"
      id="site-footer"
      class="site-footer">
      <div class="gutter-wrapper">
        <div class="footer-container">
          <div class="footer-head">
            <router-link
              :to="'/'"
              class="footer-logo"
              title="Home">
              <img
                v-if="acf_options.site_logo_footer"
                class="w-full h-full max-w-[256px] lg:max-w-[300px] ml-0 object-contain"
                :src="acf_options.site_logo_footer.url"
                :alt="acf_options.site_logo_footer.alt" />
            </router-link>
            <div class="social-media">
              <div
                class="contact-container"
                v-html="acf_options.footer_contact_information"></div>

              <div class="social-container">
                <a
                  v-if="acf_options.x"
                  :href="acf_options.x"
                  target="_blank">
                  <svg
                    width="44"
                    height="41"
                    viewBox="0 0 44 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M34.6526 0.807861H41.3995L26.6594 17.6548L44 40.5798H30.4225L19.7881 26.6759L7.61989 40.5798H0.868864L16.6349 22.5601L0 0.807861H13.9222L23.5348 13.5165L34.6526 0.807861ZM32.2846 36.5414H36.0232L11.8908 4.63413H7.87892L32.2846 36.5414Z"
                      fill="#24444B" />
                  </svg>
                </a>
                <a
                  v-if="acf_options.linkedin"
                  :href="acf_options.linkedin"
                  target="_blank">
                  <svg
                    width="49"
                    height="48"
                    viewBox="0 0 49 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M41.9675 4C43.6393 4 45 5.32403 45 6.95708V42.0408C45 43.6738 43.6393 45 41.9675 45H7.02392C5.35637 45 4 43.6738 4 42.0408V6.95708C4 5.32403 5.35637 4 7.02392 4H41.9675ZM38.9393 38.9335V28.2039C38.9393 22.9356 37.8018 18.8841 31.6445 18.8841C28.685 18.8841 26.7019 20.5064 25.8907 22.0451H25.807V19.3712H19.9781V38.9356H26.0517V29.2597C26.0517 26.7082 26.5345 24.2339 29.698 24.2339C32.8614 24.2339 32.8571 27.1524 32.8571 29.4206V38.9356H38.9371L38.9393 38.9335ZM16.1622 19.3691H10.0757V38.9335H16.1622V19.3691ZM13.1211 9.64378C11.1681 9.64378 9.59286 11.2232 9.59286 13.1695C9.59286 15.1159 11.1681 16.6953 13.1211 16.6953C15.0741 16.6953 16.6451 15.1159 16.6451 13.1695C16.6451 11.2232 15.0655 9.64378 13.1211 9.64378Z"
                      fill="#24444B" />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="footer-body">
            <p class="rights">
              © {{ currentYear }} Marea Therapeutics, Inc. All rights reserved.
            </p>
            <div class="legal-links">
              <template
                v-for="(link, indx) of acf_options.footer_links"
                :key="`footer-link-${indx}`">
                <a
                  :href="link.link.url"
                  :target="link.link.target"
                  class="mr-1"
                  >{{ link.link.title }}</a
                >
              </template>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </transition>
</template>

<script setup>
import { useStore } from '@/stores/main'
import { ref, computed, onBeforeMount, watch } from 'vue'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'
import useSiteLoading from '@/composables/useSiteLoading'

const { siteLoading } = useSiteLoading()

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const acf_options = ref(null)

const currentYear = computed(() => {
  return new Date().getFullYear()
})

watch(siteLoading, () => {
  setTimeout(() => {
    ScrollTrigger.refresh()
  }, 500)
})

onBeforeMount(async () => {
  acf_options.value = await store.acfOptions()
})
</script>

<style lang="scss">
.site-footer {
  @apply w-full mx-auto bg-marea-grey pt-xl pb-0 lg:pt-24 lg:pb-12 relative overflow-hidden;
}

.background-image {
  @apply absolute pointer-events-none left-0 w-full object-cover h-full lg:h-auto lg:w-[55%] top-0 max-w-none bg-cover;
}

.gutter-wrapper {
  @apply inner-container-with-padding;

  a {
    &:hover {
      svg {
        path {
          @apply fill-marea-yellow transition-all;
        }
      }
    }
  }
}

.footer-container {
  @apply flex flex-col gap-28 lg:gap-72;
  .footer-head {
    @apply flex flex-col lg:flex-row lg:justify-between lg:py-0;

    .footer-logo {
      @apply h-fit w-[256px] md:w-[300px];
    }

    .social-media {
      @apply flex flex-col gap-3 items-start py-20 lg:py-3;

      .contact-container {
        @apply flex flex-col;

        * {
          @apply body-small mb-4;
        }

        a {
          @apply relative;

          &:before {
            @apply content-[''] scale-0 origin-left transition-all w-0 absolute bottom-0 left-0 right-0 h-[1px] bg-marea-storm-blue block;
          }

          &:hover {
            &:before {
              @apply w-full scale-100;
            }
          }
        }
      }

      .social-container {
        @apply flex flex-row items-center py-6 relative w-full lg:py-0;

        a {
          @apply mr-4;

          &:hover {
            svg {
              path {
                @apply fill-marea-ocean-teal;
              }
            }
          }
        }
      }
    }
  }

  .footer-body {
    @apply flex flex-col text-[14px] py-4 lg:flex-row lg:justify-start lg:items-center lg:py-0 lg:gap-20;

    .rights {
      @apply text-left text-[14px] mb-4 lg:mb-0;
    }

    .legal-links {
      @apply flex flex-row flex-wrap gap-5 h-fit;

      a {
        @apply relative leading-none pb-0;

        &:before {
          @apply content-[''] origin-left transition-all w-full absolute bottom-0 left-0 right-0 h-[1px] bg-marea-storm-blue block;
        }

        &:hover {
          &:before {
            @apply w-0 scale-0;
          }
        }
      }
    }
  }
}
</style>
