<template>
  <div
    ref="intro"
    class="intro">
    <div class="intro-container">
      <div
        ref="animationContainer"
        class="intro-animation" />
    </div>
  </div>
</template>

<script setup>
import gsap from 'gsap'
import lottie from 'lottie-web'
import introJson from '@/assets/lottie/intro.json?url'
import useIntroShown from '@/composables/useIntroShown.js'
import { ref, onMounted } from 'vue'

const { setIntroComplete } = useIntroShown()
const animationContainer = ref()
const intro = ref()

onMounted(() => {
  document.documentElement.classList.add('no-overflow')

  const animation = lottie.loadAnimation({
    container: animationContainer.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    path: introJson,
  })

  if (animation) {
    setTimeout(() => {
      animation.play()
    }, 200)

    animation.addEventListener('complete', () => {
      gsap.to(animationContainer.value, {
        opacity: 0,
        duration: 1,
        onComplete: () => {
          document.documentElement.classList.remove('no-overflow')
          setIntroComplete()
        },
      })
    })
  }
})
</script>

<style lang="scss">
.intro {
  @apply fixed left-0 top-0 z-[51] h-full w-full bg-marea-storm-blue bg-gradient-to-tr;
  .intro-container {
    @apply relative left-0 flex h-[100vh] w-[100vw] items-center justify-center bg-cover bg-no-repeat;
    .intro-animation {
      @apply m-auto h-[80%] w-full md:h-[700px] md:w-[700px];
      canvas {
        @apply relative top-[-50px];
      }
    }
  }
}
</style>
