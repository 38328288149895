<template>
  <header
    id="header-nav"
    ref="header"
    data-scroll
    data-scroll-sticky
    data-scroll-speed="1"
    data-scroll-target="#root-container"
    class="no-cursor header-nav white"
    :class="`${navOpen ? 'nav-open' : ''} ${
      device === 'tablet' || device === 'mobile' ? 'mobile' : ''
    } ${sticky ? 'sticky-header' : ''}`">
    <nav
      id="nav-container"
      ref="navWrapper">
      <div class="branding">
        <div
          id="header-logos"
          class="logo-container">
          <a
            href="/"
            @click="close(false)">
            <svg
              width="300"
              height="49"
              viewBox="0 0 300 49"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M201.611 24.3972C201.611 10.1508 211.775 0 225.535 0C238.114 0 247.875 8.64329 248.881 20.4524C249.057 22.4625 249.057 24.7238 248.881 26.5831H210.089C211.02 35.7289 217.385 41.8345 225.611 41.8345C231.322 41.8345 237.108 38.9953 239.221 33.3671H247.699C245.611 42.5129 237.208 48.7944 225.535 48.7944C211.674 48.7944 201.611 38.6435 201.611 24.3972ZM240.479 20.2012C239.221 11.5579 232.001 6.95986 225.611 6.95986C217.963 6.95986 211.926 12.1609 210.416 20.2012H240.479Z"
                fill="#24444B" />
              <path
                d="M199.021 1.00494H193.411C186.443 1.00494 181.738 4.2713 181.738 12.3116V47.7641H189.889V13.1407C189.889 9.69848 191.826 8.19093 195.273 8.19093H202.115V0.979813H199.021V1.00494Z"
                fill="#24444B" />
              <path
                d="M106.519 0C102.67 0 99.7769 1.28142 98.5191 1.90956C94.2172 4.07039 91.9783 7.387 91.1229 8.84429C90.2676 7.41212 88.0287 4.09551 83.7268 1.90956C82.469 1.28142 79.576 0 75.727 0C65.6642 0 59.2744 6.45734 59.2744 17.764V47.7893H67.4252V18.3921C67.4252 11.3569 71.1987 6.98498 77.0854 6.98498C82.9721 6.98498 87.0727 11.181 87.0727 18.7941V47.7893H95.2235V18.7941C95.2235 11.1559 99.5002 6.98498 105.211 6.98498C110.921 6.98498 114.871 11.3569 114.871 18.3921V47.8145H123.022V17.7891C123.022 6.48247 116.632 0 106.569 0"
                fill="#24444B" />
              <path
                d="M152.832 0.0754897C152.329 0.0503639 151.826 0.025238 151.298 0.025238C137.688 0.025238 128.053 10.5781 128.053 24.347C128.053 38.116 137.612 48.7442 151.298 48.7442C158.694 48.7442 164.052 45.8045 167.499 41.0306V47.7392H175.549V24.3973C175.549 24.3973 175.549 1.10565 152.832 0.100616M151.977 41.3824C143.172 41.3824 136.455 34.4225 136.455 24.3722C136.455 14.3218 143.172 7.43736 151.977 7.43736C160.782 7.43736 167.423 14.5731 167.423 24.3722C167.423 34.1712 160.455 41.3824 151.977 41.3824Z"
                fill="#24444B" />
              <path
                d="M277.283 0.0754897C276.78 0.0503639 276.277 0.025238 275.749 0.025238C262.139 0.025238 252.504 10.5781 252.504 24.347C252.504 38.116 262.063 48.7442 275.749 48.7442C283.145 48.7442 288.503 45.8045 291.95 41.0306V47.7392H300V24.3973C300 24.3973 300 1.10565 277.283 0.100616M276.428 41.3824C267.623 41.3824 260.906 34.4225 260.906 24.3722C260.906 14.3218 267.623 7.43736 276.428 7.43736C285.233 7.43736 291.874 14.5731 291.874 24.3722C291.874 34.1712 284.906 41.3824 276.428 41.3824Z"
                fill="#24444B" />
              <path
                d="M36.7588 0.32657C30.5199 0.427074 25.8659 5.45224 25.715 11.2563C25.564 17.8644 30.9979 22.8142 36.8595 22.7639C43.0983 22.8393 48.2555 17.7639 48.2052 11.4824C48.2052 5.25123 43.0732 0.226067 36.7588 0.32657Z"
                fill="#24444B" />
              <path
                d="M22.4447 36.8596C22.495 30.6786 17.2372 25.3519 10.6461 25.7037C4.58335 26.0303 -0.0706593 31.0555 0.00481118 37.0354C0.0802816 43.4425 5.36321 48.3421 11.6273 48.1662C17.6901 47.9903 22.6208 42.8144 22.4698 36.8847"
                fill="#24444B" />
              <path
                d="M36.3809 25.6786C30.4439 25.9801 25.8905 30.8043 25.7395 36.6083V36.6586C25.488 43.141 21.6138 46.332 19.6516 47.5381C19.3749 47.7139 19.5007 48.1411 19.8025 48.1411H37.5129C43.4751 47.8647 48.3555 42.739 48.2046 36.8596C48.2549 30.6786 42.9971 25.352 36.406 25.7037H36.3809V25.6786Z"
                fill="#24444B" />
              <path
                d="M11.8283 22.7642C17.7653 22.4627 22.3187 17.6385 22.4697 11.8345V11.7842C22.7212 5.30174 26.5954 2.11076 28.5576 0.904722C28.8595 0.753967 28.7337 0.326828 28.4067 0.326828H10.6963C4.70895 0.603212 -0.171477 5.72888 0.00462064 11.6083C-0.045693 17.7893 5.21208 23.116 11.8032 22.7642H11.8283Z"
                fill="#24444B" />
            </svg>
          </a>
        </div>
        <div
          class="mobile-nav"
          @click="toggle">
          <span class="line line-one"></span>
          <span class="line line-two"></span>
          <span class="line line-three"></span>
        </div>
      </div>

      <div class="nav-container-wrapper">
        <ul
          ref="nav"
          class="nav-container">
          <template v-if="introShown">
            <transition-group
              v-for="(item, index) in menu.menuItems.nodes"
              :key="item.id"
              tag="li"
              appear
              class="nav-container-items"
              name="fade"
              @click="item.cssClasses[0] === '' ? close(false) : null">
              <NavMenuItem
                :key="item.id"
                :data-ind="index"
                :item="item"
                :mobile="device === 'mobile' ? 'mobile-menu-show' : ''"
                @click="toggle" />
            </transition-group>
          </template>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script setup>
// import { DotLottieVue } from '@lottiefiles/dotlottie-vue'
// import navAnimation from '@/assets/lottie/logo_animated.json?url'
import NavMenuItem from './NavMenuItem.vue'
import { ref, watch, onMounted } from 'vue'
import { useStore } from '@/stores/main'
import useUtils from '@/composables/useUtils.js'
import useIntroShown from '@/composables/useIntroShown.js'
import useSmoothScroll from '@/composables/useSmoothScroll.js'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const store = useStore()
const menu = ref(store.menu({ name: 'main-menu' }))
const header = ref()
const sticky = ref()
const navOpen = ref()
const transitioning = ref(false)
const scrollPosition = ref(0)
const { device } = useUtils()
const { introShown } = useIntroShown()
const { smoothScroll } = useSmoothScroll()
// const headerAnimation = ref()

watch(scrollPosition, (newVal) => {
  scrollPosition.value = newVal
  if (newVal > 20) {
    sticky.value = true
  } else {
    sticky.value = false
  }
})

// watch(introShown, (newVal) => {
//   if (newVal) {
//     const dotLottie = headerAnimation.value.getDotLottieInstance()
//     dotLottie.play()
//     dotLottie.addEventListener('frame', ({ currentFrame }) => {
//       if (currentFrame > 60) {
//         dotLottie.pause()
//       }
//     })
//   }
// })

onMounted(() => {
  // if (introShown.value) {
  //   const dotLottie = headerAnimation.value.getDotLottieInstance()
  //   dotLottie.addEventListener('frame', ({ currentFrame }) => {
  //     if (currentFrame < 60) {
  //       dotLottie.play()
  //     } else {
  //       dotLottie.pause()
  //     }
  //   })
  // }

  stickyOnScroll()
  smoothScroll.on('scroll', (e) => {
    handleScroll(e)
  })
})

const handleScroll = (e) => {
  scrollPosition.value = e.animatedScroll // Adjust the threshold as needed
}

const toggle = () => {
  navOpen.value = !navOpen.value
}
const close = (data) => {
  if (data !== undefined) navOpen.value = data
  else navOpen.value = !navOpen.value
}

const stickyOnScroll = () => {
  if (
    scrollPosition.value > 0 &&
    device.value !== 'tablet' &&
    device.value !== 'mobile'
  ) {
    header.value.classList.add('sticky')
    sticky.value = true
  } else {
    header.value.classList.remove('sticky')
    sticky.value = false
  }
}

watch(
  () => [navOpen.value, device.value],
  ([newVal, newDevice]) => {
    transitioning.value = true
    if (newVal && newDevice !== 'desktop') {
      smoothScroll.stop()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (newDevice === 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 1,
        opacity: 1,
        stagger: 0.1,
        delay: 1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    } else if (!newVal && newDevice !== 'desktop') {
      smoothScroll.start()
      gsap.to('.nav-container-items', {
        duration: 0.5,
        opacity: 0,
        stagger: 0.1,
        onComplete: () => {
          transitioning.value = false
        },
      })
    }
  }
)
</script>

<style lang="scss">
#header-nav.mobile {
  @apply fixed left-0 right-0 top-0;
  nav {
    @apply relative bg-transparent transition-all duration-200;

    &#nav-container {
      &.yellow {
        .branding {
          .mobile-nav {
            @apply bg-marea-yellow;
          }
        }
      }
    }
  }
  .nav-container-wrapper {
    @apply pointer-events-none absolute left-0 top-full -z-1 h-screen opacity-0 w-screen origin-top bg-marea-white transition-all delay-700 duration-1000 ease-in-out;
    @apply bg-cover bg-center bg-no-repeat;
    .nav-container {
      @apply mx-auto w-[96%] pt-24;
      .nav-container-items {
        @apply bg-transparent opacity-0;
      }
    }
  }
}

#header-nav {
  @apply fixed left-0 right-0 top-0 shadow-none transition-all duration-300 ease-in-out;

  .logo-container {
    &.blue {
      svg {
        path {
          @apply fill-marea-ocean-teal;
        }
      }
    }
    &.yellow {
      svg {
        path {
          @apply fill-marea-yellow;
        }
      }
    }
    &.grey {
      svg {
        path {
          @apply fill-marea-grey;
        }
      }
    }

    svg {
      @apply w-48 fill-marea-storm-blue transition-all lg:w-full;

      path {
        @apply fill-marea-storm-blue;
      }
    }
  }
  .nav-container-wrapper {
    @apply w-full transition-all lg:h-auto;
  }
}

#header-nav.sticky-header {
  @apply bg-transparent backdrop-blur-2xl;
}

#header-nav.nav-open {
  @apply max-h-[100vh] lg:max-h-[110px];

  .branding {
    .mobile-nav {
      .line-one {
        @apply translate-x-[0px] translate-y-[6px] rotate-45 transform;
      }
      .line-three {
        @apply -translate-x-[0px] -translate-y-[6px] -rotate-45 transform;
      }
      .line-two {
        @apply opacity-0;
      }
    }
  }

  .nav-container-wrapper {
    @apply pointer-events-auto top-0 opacity-100;
  }
}
.header-nav {
  @apply top-0 z-10 mb-0 mt-0 flex w-screen max-w-full items-stretch justify-center bg-transparent lg:overflow-visible lg:pb-2 lg:pt-6;

  nav {
    @apply container flex flex-col items-stretch pt-0 transition-all duration-300 ease-in-out xl:inner-container lg:flex-row lg:items-center lg:justify-between lg:px-lg lg:py-0 xl:px-0;

    .nav-container {
      @apply my-0 flex flex-col items-center justify-start px-lg lg:rounded-30 lg:bg-marea-ocean-teal lg:py-4 lg:px-8 lg:w-fit lg:mr-0 lg:ml-auto lg:flex-row lg:justify-end 
      lg:gap-6 xl:gap-16;
      li {
        @apply relative w-full border border-b-0 border-l-0 border-r-0 border-t-[1px] border-marea-storm-blue text-center lg:w-auto lg:border-t-0;

        &:first-child {
          @apply border-t-0;

          &:before {
            @apply hidden;
          }
        }

        &:before {
          @apply content-[''] hidden lg:block h-full w-[1px] bg-marea-storm-blue -left-4 top-0 bottom-0 absolute xl:-left-8;
        }
      }
    }

    &#nav-container {
      &.yellow {
        .nav-container {
          @apply lg:bg-marea-yellow;

          .menu-items {
            a.router-link-active {
              &:before {
                @apply bg-teal-blue-gradient;
              }
            }
          }
        }
      }
    }
  }
  .branding {
    @apply flex w-full items-center justify-between p-3 text-center shadow-none md:text-left lg:max-h-[60px] lg:w-[242px] lg:flex-[1_0_auto] lg:p-0 xl:p-0;
    .logo-container {
      @apply text-marea-storm-blue flex h-full items-center lg:inline-block xl:max-h-full;

      svg {
        @apply h-full max-h-[60px] max-w-[143px] w-[90%] lg:max-w-[253px];
      }
    }
    .mobile-nav {
      @apply float-right h-9 w-9 bg-marea-ocean-teal rounded-full flex flex-col justify-center items-center lg:hidden;
      .line {
        @apply mb-1 transition-all duration-[0.5s] ease-in-out;

        &:last-child {
          @apply mb-0;
        }
      }
      .line:before {
        @apply block h-[2px] w-[20px] bg-marea-storm-blue content-[''];
      }
    }
  }

  .nav-container {
    @apply m-0 flex h-full w-full items-start justify-start text-left transition duration-500 ease-in-out;
    .nav-container-items {
      @apply flex w-full items-center text-center lg:w-auto;
      div.menu-items {
        @apply header-2 mb-0 flex h-full items-start justify-start text-left lg:mb-0 lg:body-small tracking-normal;
        a {
          // @apply font-nunitoSans;
        }
      }
    }
  }
}
</style>
